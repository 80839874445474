<template>
  <div class="list-info">
    <top-bar :title="'检查'" :left="true"></top-bar>
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入检查人姓名" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <div class="filterBar">
      <van-row>
        <van-col span="8">
          <p @click="gridShow = !gridShow" :style="{color:gridShow?'#387FF5':'#666666'}">{{gridName}}
            <img :src="require(`@/assets/img/${gridShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="dateBarShow = !dateBarShow" :style="{color:dateBarShow?'#387FF5':'#666666'}">检查时间
            <img :src="require(`@/assets/img/${dateBarShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="typeShow = !typeShow" :style="{color:typeShow?'#387FF5':'#666666'}">{{typeName}}
            <img :src="require(`@/assets/img/${typeShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <van-popup v-model="gridShow" position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="gridList" value-key="label" @confirm="gridConfirm" @cancel="gridShow = false"  />
    </van-popup>
    <div class="dateBar" v-show="dateBarShow">
      <van-row>
        <van-col span="8" @click="beginDateShow = !beginDateShow"><span>{{selectDate.beginDate == ''?'最早':selectDate.beginDate}}</span></van-col>
        <van-col span="2" ><span>-</span></van-col>
        <van-col span="8" @click="endDateShow = !endDateShow"><span>{{selectDate.endDate== ''?'至今':selectDate.endDate}}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <van-popup v-model="beginDateShow" position="bottom"  >
      <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false"  type="date"
                           :formatter="formatDate" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="endDateShow" position="bottom"  >
      <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" type="date" :formatter="formatDate"
                           :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)"/>
    </van-popup>
    <van-popup v-model="typeShow" position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="typeList" value-key="label" @confirm="typeConfirm" @cancel="typeShow = false"  />
    </van-popup>
    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="">
    </div>
    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
          <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
            <div class="title">
              <div class="title-left">
                {{item.createUserName + ' | ' + item.createTime}}
              </div>
              <div class="title-right">
                {{item.checkTypeName}}
              </div>
            </div>
            <div class="content">
              <van-image :src="item.filesPath !== null?item.filesPath[0].url:require('@/assets/img/flower.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text">{{item.buildingName}}</div>
                <div class="bottom-text">{{item.checkDes}}</div>
              </div>
              <van-icon name="arrow" color="#666" class="content-icon"/>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import { mapMutations } from 'vuex'
export default {
  name: 'censor',
  components :{
    topBar
  },
  data() {
    return {
      gridName: '所属网格',
      typeName: '检查类型',
      loading: false,
      pullLoading: false,
      finished: false,
      endDateShow: false,
      beginDateShow: false,
      dateBarShow: false,
      gridShow: false,
      typeShow: false,
      page: 0,
      limit: 10,
      orgId: 0,
      searchValue: '',
      selectDate: {
        endDate: '',
        beginDate: ''
      },
      gridList: [],
      typeList: [],
      totalCount: 0,
      dataList: []
    };
  },
  methods: {
    ...mapMutations,
    getInfo (id) {
      this.$router.push({path: '/censor-add', query: {id: id}})
    },
    // 获取字典
    getDictTypeList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/checkTemplate/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: 1,
          limit: -1,
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let list = []
          data.page.list.map(item => {
            list.push({value: item.id, label: item.name})
          })
          this.typeList = list
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/check/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          orgId: this.orgId,
          searchValue: this.searchValue,
          beginTime: this.selectDate.beginDate,
          endTime: this.selectDate.endDate,
          gridId: this.gridId,
          type: this.typeId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.pullLoading =false
          this.totalCount = data.list.totalCount
          if (this.dataList.length == data.list.totalCount) {
            this.finished = true
          }
          let dataList = data.list.list
          dataList.map(item => {
            if (item.inspectionTime) {
              item.inspectionTime = item.inspectionTime.split(' ')[0]
            }
          })
          this.dataList = this.dataList.concat(data.list.list)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    getGridList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/grid/info/listByUser'),
        method: 'post',
        params: this.$http.adornParams({
          community: this.$globalData.userInfo.orgId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.gridList = data.grids
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    formatDate (type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    onPullDownRefresh(e) {
      this.finished=false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    onSearch(val) {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.getDataList()
    },
    beginDateConfim (value) {
      let d = new Date(this.selectDate.endDate)
      if (this.selectDate.endDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selectDate.beginDate = formatterDate(value)
      this.beginDateShow = false
    },
    endDateConfim (value) {
      let d = new Date(this.selectDate.beginDate)
      if (this.selectDate.beginDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selectDate.endDate = formatterDate(value)
      this.endDateShow = false
    },
    changeDate () {
      this.dataList = []
      this.page = 0
      this.getDataList()
    },
    dateClose () {
      this.selectDate = {
        endDate: '',
        beginDate: ''
      }
    },
    gridConfirm (value,index) {
      this.page = 0
      this.dataList = []
      this.gridName = value.label
      this.gridId = value.value
      this.getDataList()
      this.gridShow = false
    },
    typeConfirm (value,index) {
      this.page = 0
      this.dataList = []
      this.typeName = value.label
      this.typeId = value.value
      this.getDataList()
      this.typeShow = false
    },
    goAdd () {
      this.$router.push('/censor-add')
    }
  },
  created () {
    this.orgId = this.$orgId
    this.getDataList()
    this.getGridList()
    this.getDictTypeList()
  }
}
</script>
